<template>
  <!-- Add Icon -->
  <svg
    v-if="name === 'addIcon'"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.625 9.125H11.375V3.875C11.375 3.392 10.9839 3 10.5 3C10.0161 3 9.625 3.392 9.625 3.875V9.125H4.375C3.89113 9.125 3.5 9.517 3.5 10C3.5 10.483 3.89113 10.875 4.375 10.875H9.625V16.125C9.625 16.608 10.0161 17 10.5 17C10.9839 17 11.375 16.608 11.375 16.125V10.875H16.625C17.1089 10.875 17.5 10.483 17.5 10C17.5 9.517 17.1089 9.125 16.625 9.125"
      fill="white"
    />
  </svg>

  <!-- Empty Listing Icon -->
  <svg
    v-else-if="name === 'emptyListingIcon'"
    width="250"
    height="250"
    viewBox="0 0 250 250"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="125" cy="125" r="125" fill="#F2F5FA" />
    <g clip-path="url(#clip0_4789_148597)">
      <path
        d="M185.679 234.999L65.4628 236.838C51.1089 223.88 39.076 196.239 43.1997 178.754C47.8866 158.802 54.6242 141.63 61.6997 131.229C64.8093 126.678 67.964 123.405 71.0285 121.746C85.833 113.743 161.072 112.375 176.575 121.746C178.603 123.001 180.699 124.974 182.795 127.552C190.479 137.147 198.072 155.193 203.503 175.1C209.001 195.388 200.483 221.661 185.679 234.999Z"
        fill="#B23937"
      />
      <path
        opacity="0.5"
        d="M182.772 127.552C158.12 129.502 141.085 146.226 126.686 147.638C114.721 150.732 77.9684 120.423 61.6992 131.229C64.8088 126.678 67.9635 123.405 71.0281 121.746C85.8326 113.743 161.072 112.375 176.575 121.746C178.603 122.979 180.676 124.952 182.772 127.552Z"
        fill="black"
      />
      <path
        d="M173.939 107.243C173.443 123.294 153.727 133.449 140.612 141.385C125.334 150.621 125.132 149.231 100.412 133.046C88.8752 125.491 75.1749 118.497 72.7187 108.185C67.3332 85.8568 97.2126 39.5645 124.681 39.5645C152.149 39.5645 174.841 78.7504 173.939 107.243Z"
        fill="black"
      />
      <path
        d="M157.94 94.5523C157.737 97.3097 157.264 99.9998 156.633 102.555C153.501 114.594 145.795 123.942 137.592 128.941C133.243 131.631 128.759 133.066 124.681 133.066C124.23 133.066 123.779 133.021 123.306 132.998C113.166 131.99 97.6856 120.22 92.8409 102.623C92.0973 100.045 91.6241 97.3545 91.3988 94.5523C91.2185 92.3554 91.196 90.2257 91.2636 88.1409C92.0297 65.9475 106 50.4121 124.658 50.4121C143.293 50.4121 157.287 65.9027 158.053 88.0737C158.143 90.1585 158.12 92.3106 157.94 94.5523Z"
        fill="#F7A58E"
      />
      <path
        d="M94.5309 102.265C94.0127 102.511 93.4042 102.623 92.8409 102.623C90.3172 102.668 87.6131 100.695 86.2837 97.557C84.6387 93.7236 85.6527 89.6436 88.5595 88.3658C89.3933 88.0071 90.3397 87.9399 91.2636 88.1192C93.4493 88.5451 95.6576 90.361 96.8068 93.0735C98.4743 96.9293 97.4377 101.009 94.5309 102.265Z"
        fill="#F7A58E"
      />
      <path
        d="M162.493 97.5564C161.253 100.426 158.91 102.264 156.634 102.556C155.823 102.69 155.012 102.6 154.245 102.287C152.668 101.614 151.654 100.045 151.339 98.1617C151.023 96.6597 151.203 94.9111 151.924 93.2074C151.924 93.1625 151.97 93.1401 151.97 93.0953C153.231 90.1586 155.688 88.2531 158.076 88.0738C158.82 88.0065 159.541 88.1186 160.262 88.3876C163.124 89.643 164.138 93.723 162.493 97.5564Z"
        fill="#F7A58E"
      />
      <path
        d="M162.492 97.5572C161.253 100.427 158.909 102.265 156.633 102.556C153.501 114.595 145.795 123.943 137.592 128.942C143.271 123.225 152.014 106.121 150.211 103.072C148.409 100.023 137.007 106.704 133.198 102.87C127.204 96.8174 126.596 97.0192 120.602 102.624C113.707 109.08 106.338 100.83 99.7362 102.018C93.1338 103.206 109.583 130.242 123.306 132.999C113.166 131.991 97.6856 120.221 92.8409 102.624C90.3172 102.668 87.6131 100.696 86.2837 97.5572C84.6387 93.7238 85.6527 89.6438 88.5595 88.366C89.3933 88.0073 90.3397 87.9401 91.2636 88.1194C92.0297 65.926 106 50.3906 124.658 50.3906C143.293 50.3906 157.287 65.8812 158.053 88.0522C158.796 87.9849 159.518 88.097 160.239 88.366C163.123 89.6438 164.137 93.7238 162.492 97.5572Z"
        fill="#B3644F"
      />
      <path
        d="M158.66 87.6266V89.9581C158.615 92.5361 157.331 94.733 155.145 96.5488C150.954 100.046 143.473 102.22 135.068 103.072C131.801 102.871 129.119 93.6794 126.415 93.6794C123.689 93.6794 122.922 102.422 117.176 103.072C107.307 102.086 98.3158 99.4183 93.8091 95.1814C91.8036 93.2983 90.6769 91.1014 90.722 88.613V85.4745C90.9248 77.875 158.931 79.9598 158.66 87.6266Z"
        fill="black"
      />
      <path
        d="M121.729 105.875C121.729 105.875 122.044 110.224 126.686 110.224C131.328 110.224 131.643 106.054 131.643 106.054C131.643 106.054 129.998 108.946 126.528 108.946C123.035 108.946 121.729 105.875 121.729 105.875Z"
        fill="#F7CEC3"
      />
      <path
        d="M118.395 117.397C120.513 119.123 131.802 118.517 132.275 117.397C132.681 116.433 123.352 118.069 118.395 117.397Z"
        fill="#B3644F"
      />
      <path
        d="M119.814 119.055C119.814 119.055 125.087 120.131 130.18 119.055C131.149 119.055 129.211 120.041 128.174 120.198C122.586 121.072 119.814 119.055 119.814 119.055Z"
        fill="#F7CEC3"
      />
      <path
        d="M155.147 96.5486C150.955 100.046 143.474 102.22 135.069 103.072C131.802 102.87 129.121 93.6792 126.417 93.6792C123.69 93.6792 122.924 102.422 117.178 103.072C107.308 102.086 98.3172 99.4181 93.8105 95.1812C99.2411 90.5407 110.621 87.3574 123.78 87.3574C138.202 87.3574 150.505 91.1684 155.147 96.5486Z"
        fill="white"
      />
      <path
        d="M177.41 94.8455C173.083 67.9444 153.119 36.6719 126.664 36.6719C105.077 36.6719 79.344 67.6754 72.2459 91.6846C71.277 94.98 70.6461 98.1633 70.4207 101.1C70.2179 104.014 70.3982 106.682 71.0517 109.036C73.981 119.729 97.0778 135.511 112.175 143.514C114.879 144.949 122.405 147.594 125.921 147.684C128.377 148.76 135.047 145.375 137.278 144.187C152.871 135.825 177.838 120.872 178.266 106.996V106.704C178.379 102.938 178.086 98.9479 177.41 94.8455ZM126.394 144.276C120.873 135.13 86.1266 115.492 87.9968 108.027C92.774 89.0842 108.412 80.2741 126.056 81.2156C145.976 82.2692 161.388 94.2626 162.583 104.306C162.11 113.228 132.388 134.144 126.394 144.276Z"
        fill="#B23937"
      />
      <path
        d="M158.661 105.538C153.591 114.55 143.631 127.485 134.324 133.179C131.237 135.085 128.218 136.183 125.491 136.071C124.094 136.026 122.72 135.757 121.323 135.331C106.451 130.803 92.7051 107.04 92.7051 107.04C100.704 110.694 106.473 110.784 111.07 109.663C117.334 108.139 121.39 104.35 125.987 104.395C129.547 104.417 134.009 108.609 141.468 109.416C145.929 109.887 151.518 109.147 158.661 105.538Z"
        fill="black"
      />
      <path
        d="M141.447 109.416C141.018 116.635 138.359 126.88 134.326 133.179C131.239 135.085 128.219 136.183 125.493 136.071C124.096 136.026 122.721 135.757 121.324 135.331C115.24 129.503 111.409 117.576 111.049 109.663C117.313 108.139 121.369 104.35 125.966 104.395C129.526 104.44 133.988 108.609 141.447 109.416Z"
        fill="#262626"
      />
      <path
        d="M114.248 144.3C98.6996 137.284 73.1692 119.26 70.2398 108.567C69.6089 106.213 69.4061 103.545 69.6089 100.631C71.0285 107.917 77.1802 115.785 84.6613 122.914C93.5845 131.41 111.994 136.521 114.248 144.3Z"
        fill="white"
      />
      <path
        d="M177.432 106.234V106.526C177.004 120.402 150.167 136.902 133.83 145.039C138.54 133.225 173.128 124.931 177.432 106.234Z"
        fill="white"
      />
      <path
        d="M117.288 184.406C117.243 184.406 117.198 184.406 117.153 184.383C116.905 184.316 116.77 184.047 116.838 183.8C122.133 165.552 120.623 148.963 118.347 145.556C118.212 145.332 118.257 145.063 118.483 144.906C118.708 144.771 118.978 144.816 119.136 145.04C121.547 148.672 123.192 165.261 117.739 184.047C117.694 184.271 117.491 184.406 117.288 184.406Z"
        fill="#F0F0F0"
      />
      <path
        d="M117.019 147.058C116.974 147.058 116.929 147.058 116.884 147.036C116.636 146.968 116.501 146.722 116.568 146.475C116.591 146.408 116.996 145.018 118.145 144.57C118.821 144.301 119.61 144.435 120.421 144.928C120.647 145.063 120.714 145.354 120.579 145.556C120.444 145.78 120.151 145.848 119.948 145.713C119.385 145.354 118.889 145.265 118.483 145.422C117.762 145.691 117.469 146.722 117.469 146.722C117.402 146.924 117.222 147.058 117.019 147.058Z"
        fill="#F0F0F0"
      />
      <path
        d="M131.283 184.405C131.08 184.405 130.9 184.27 130.832 184.068C125.379 165.282 127.024 148.693 129.435 145.062C129.57 144.838 129.863 144.793 130.088 144.927C130.314 145.062 130.359 145.353 130.224 145.577C127.97 148.985 126.438 165.574 131.733 183.822C131.801 184.068 131.666 184.315 131.418 184.405C131.373 184.382 131.328 184.405 131.283 184.405Z"
        fill="#F0F0F0"
      />
      <path
        d="M131.575 147.058C131.372 147.058 131.192 146.923 131.124 146.721C131.124 146.721 130.831 145.69 130.11 145.421C129.705 145.264 129.231 145.376 128.646 145.712C128.42 145.847 128.15 145.78 128.015 145.556C127.879 145.331 127.947 145.062 128.172 144.928C129.006 144.412 129.772 144.3 130.448 144.569C131.598 145.018 132.003 146.43 132.026 146.475C132.093 146.721 131.958 146.968 131.71 147.035C131.665 147.058 131.62 147.058 131.575 147.058Z"
        fill="#F0F0F0"
      />
      <path
        opacity="0.5"
        d="M177.702 95.2497C171.325 75.3653 158.346 53.3961 129.413 51.1319C98.3614 48.6884 77.8559 74.4686 72.5605 92.0888C78.6671 68.0796 103.589 36.0449 126.686 36C153.118 35.9552 173.398 68.3486 177.702 95.2497Z"
        fill="black"
      />
      <path
        d="M196.945 240.001H52.0776L32.203 171.313C31.2341 167.973 33.7579 164.633 37.2505 164.633H211.727C215.22 164.633 217.744 167.973 216.775 171.313L196.945 240.001Z"
        fill="#474747"
      />
      <path
        d="M131.419 215.924C134.19 214.198 136.263 211.463 136.759 208.19C137.796 201.509 132.05 195.277 123.78 195.277C115.51 195.277 109.764 201.509 110.8 208.19C111.319 211.53 113.459 214.31 116.299 216.036L116.456 217.74H116.479L116.637 219.376C116.682 219.869 117.2 220.295 117.853 220.34C118.597 220.385 119.205 219.959 119.16 219.421L119.07 217.919C119.07 217.829 119.16 217.74 119.296 217.74C119.431 217.74 119.521 217.807 119.543 217.919L119.634 219.376C119.656 219.869 120.174 220.295 120.805 220.34C121.549 220.385 122.18 219.959 122.157 219.421L122.135 217.919C122.135 217.829 122.225 217.74 122.36 217.74C122.495 217.74 122.585 217.807 122.585 217.919L122.608 219.376C122.608 219.869 123.104 220.295 123.757 220.34C124.501 220.385 125.132 219.959 125.154 219.421L125.177 217.919C125.177 217.829 125.29 217.74 125.402 217.74C125.515 217.74 125.628 217.807 125.628 217.919L125.582 219.421C125.56 219.937 126.123 220.363 126.799 220.363C127.475 220.363 128.061 219.937 128.106 219.421L128.196 217.919C128.196 217.829 128.309 217.74 128.444 217.74C128.579 217.74 128.67 217.807 128.67 217.919L128.579 219.421C128.557 219.937 129.075 220.363 129.774 220.363C130.45 220.363 131.058 219.937 131.126 219.421L131.419 216.395C131.419 216.372 131.419 216.35 131.419 216.305V215.924ZM129.008 206.015C131.531 206.015 133.356 207.786 133.131 209.871C132.906 211.844 130.855 213.391 128.557 213.391C126.258 213.391 124.388 211.844 124.411 209.871C124.433 207.786 126.484 206.015 129.008 206.015ZM123.622 212.718C124.208 212.718 124.974 214.377 124.659 214.624C124.343 214.87 122.676 214.848 122.383 214.624C122.112 214.4 123.059 212.718 123.622 212.718ZM113.933 209.871C113.685 207.786 115.51 206.015 118.034 206.015C120.557 206.015 122.608 207.786 122.653 209.871C122.676 211.844 120.828 213.391 118.529 213.391C116.231 213.391 114.18 211.844 113.933 209.871Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4789_148597">
        <rect
          width="185"
          height="204"
          fill="white"
          transform="translate(32 36)"
        />
      </clipPath>
    </defs>
  </svg>

  <!-- Eye Icon -->
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-else-if="name === 'eyeIcon'"
  >
    <g clip-path="url(#clip0_8347_75566)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9423 7.70266C14.2327 3.42854 10.9447 1.48085 7.65821 1.65085C4.40956 1.81888 1.34442 4.05225 0.0451446 7.73352L-0.0488281 7.99978L0.0451446 8.26603C1.34442 11.9473 4.40956 14.1807 7.65821 14.3487C10.9447 14.5187 14.2327 12.571 15.9423 8.29689L16.0612 7.99978L15.9423 7.70266ZM7.99961 11.9998C10.2087 11.9998 11.9996 10.2089 11.9996 7.99978C11.9996 5.79064 10.2087 3.99978 7.99961 3.99978C5.79047 3.99978 3.99961 5.79064 3.99961 7.99978C3.99961 10.2089 5.79047 11.9998 7.99961 11.9998Z"
        fill="#575959"
      />
      <path
        d="M10.3996 7.99978C10.3996 9.32526 9.32509 10.3998 7.99961 10.3998C6.67413 10.3998 5.59961 9.32526 5.59961 7.99978C5.59961 6.6743 6.67413 5.59978 7.99961 5.59978C9.32509 5.59978 10.3996 6.6743 10.3996 7.99978Z"
        fill="#575959"
      />
    </g>
    <defs>
      <clipPath id="clip0_8347_75566">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>

  <!-- Close sign Icon -->
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="cursor: pointer"
    @click="$emit('hideModal')"
    v-else-if="name === 'closeSignIcon'"
  >
    <path
      d="M9.41385 7.99985L15.7069 1.70685C15.8944 1.51934 15.9997 1.26503 15.9997 0.999849C15.9997 0.734673 15.8944 0.480357 15.7069 0.292849C15.5193 0.105341 15.265 0 14.9998 0C14.7347 0 14.4804 0.105341 14.2928 0.292849L7.99985 6.58585L1.70685 0.292849C1.614 0.200005 1.50378 0.126356 1.38247 0.0761092C1.26117 0.0258621 1.13115 0 0.999849 0C0.868547 0 0.738531 0.0258621 0.617224 0.0761092C0.495916 0.126356 0.385694 0.200005 0.292849 0.292849C0.105341 0.480357 0 0.734673 0 0.999849C0 1.26503 0.105341 1.51934 0.292849 1.70685L6.58585 7.99985L0.292849 14.2928C0.105341 14.4804 0 14.7347 0 14.9998C0 15.265 0.105341 15.5193 0.292849 15.7069C0.480357 15.8944 0.734673 15.9997 0.999849 15.9997C1.26503 15.9997 1.51934 15.8944 1.70685 15.7069L7.99985 9.41385L14.2928 15.7069C14.3855 15.8 14.4957 15.874 14.617 15.9244C14.7383 15.9749 14.8684 16.0009 14.9998 16.0009C15.1313 16.0009 15.2614 15.9749 15.3827 15.9244C15.504 15.874 15.6142 15.8 15.7069 15.7069C15.7998 15.6141 15.8735 15.5039 15.9238 15.3825C15.9742 15.2612 16 15.1312 16 14.9998C16 14.8685 15.9742 14.7385 15.9238 14.6172C15.8735 14.4958 15.7998 14.3856 15.7069 14.2928L9.41385 7.99985Z"
      fill="#575959"
    />
  </svg>
</template>

<script>
export default {
  props: ["name"],
};
</script>

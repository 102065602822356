/* eslint-disable */
import { createApp, Suspense } from "vue";
import { createPinia } from "pinia";

import router from "./router";

//Vue Componenets
import App from "./App.vue";

//--> global and UI components
import BaseButton from "./components/UI/BaseButton.vue";
import LabelInput from "./components/UI/LabelWithInput.vue";
import NotificationMessage from "./components/UI/NotificationMessage/NotificationMessage.vue";
import TooltipWrapper from "./components/UI/TooltipWrapper/TooltipWrapper.vue";
// import BaseInput from "./components/UI/BaseInput.vue";
// import ConfirmMessage from "./components/UI/ConfirmMessage.vue";

//Primevue, Bootstrap and other components
import PrimeVue from "primevue/config";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Row from "primevue/row";
import Tooltip from "primevue/tooltip";
import Dropdown from "primevue/dropdown";
import ConfirmationService from "primevue/confirmationservice";
// import ToastService from "primevue/toastservice";
// import Toast from "primevue/toast";

//Primevue and Bootstrap css
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons

const pinia = createPinia();
const app = createApp(App);

app.component("Suspense", Suspense);
app.component("base-button", BaseButton);
app.component("label-input", LabelInput);
app.component("notification-message", NotificationMessage);
// app.component("base-input", BaseInput);
app.component("tooltip-wrapper", TooltipWrapper);
// app.use(VueAxios, axios);
// app.component("confirm-message", ConfirmMessage);

//primevue components
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Row", Row);
app.component("Dropdown", Dropdown);
// app.component("Toast", Toast);

app.use(PrimeVue);
app.directive("tooltip", Tooltip);
app.use(ConfirmationService);
// app.use(ToastService);

app.use(pinia);
app.use(router);
app.mount("#app");

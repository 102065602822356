<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5007 0.833984C5.43815 0.833984 1.33398 4.93815 1.33398 10.0007C1.33398 15.0631 5.43815 19.1673 10.5007 19.1673C15.5631 19.1673 19.6673 15.0631 19.6673 10.0007C19.6673 4.93815 15.5631 0.833984 10.5007 0.833984ZM14.474 8.45065C14.5471 8.36702 14.6028 8.2696 14.6378 8.16412C14.6728 8.05865 14.6863 7.94725 14.6775 7.83647C14.6688 7.7257 14.638 7.61779 14.587 7.51909C14.5359 7.42039 14.4656 7.3329 14.3803 7.26177C14.2949 7.19063 14.1962 7.13729 14.0899 7.10487C13.9836 7.07245 13.8719 7.06162 13.7614 7.07301C13.6509 7.08439 13.5437 7.11777 13.4463 7.17118C13.3488 7.22458 13.2631 7.29694 13.194 7.38398L9.61065 11.6832L7.75648 9.82815C7.59932 9.67635 7.38881 9.59236 7.17032 9.59426C6.95182 9.59615 6.74281 9.68379 6.5883 9.8383C6.43379 9.99281 6.34615 10.2018 6.34426 10.4203C6.34236 10.6388 6.42635 10.8493 6.57815 11.0065L9.07815 13.5065C9.16003 13.5883 9.25806 13.6522 9.366 13.694C9.47393 13.7359 9.5894 13.7548 9.70504 13.7495C9.82068 13.7443 9.93396 13.715 10.0377 13.6635C10.1414 13.6121 10.2332 13.5396 10.3073 13.4506L14.474 8.45065Z"
      fill="#07D09E"
    />
  </svg>
</template>

<template>
  <div class="search-container">
    <Dropdown
      class="searchDropDown"
      :options="searchOptions"
      v-model="store.searchBy"
      optionLabel="name"
      optionValue="value"
      style="border: 1px solid #d8d8d8; border-radius: 8px"
    ></Dropdown>
    <!-- <input v-model="store.searchTerm" type="text" class="search-inp" /> -->
    <input
      :value="localSearchTerm"
      type="text"
      class="search-inp"
      @change="setLocalSearchTerm"
    />
    <button class="search-btn" @click="sendSearchTerm">
      <SearchSvg />
    </button>
  </div>
</template>

<script>
import { searchAndFilter } from "@/stores/searchAndFilterListing";
import SearchSvg from "./Icons/SearchSvg.vue";
import emitter from "@/services/eventBus";
export default {
  mounted() {
    emitter.on("clear-filters", () => {
      this.localSearchTerm = "";
    });
    emitter.on("clear-input", () => {
      this.localSearchTerm = "";
      this.filterByDate = null;
    });
  },
  components: { SearchSvg },
  methods: {
    setLocalSearchTerm(event) {
      if (this.store.searchBy !== "fraud_labs_id") {
        this.localSearchTerm = "#" + event.target.value;
      }
      this.localSearchTerm = event.target.value;
    },
    // This function is meant to change search keyword inside the pinia store on click
    sendSearchTerm() {
      this.store.searchTerm = this.localSearchTerm;
    },
  },
  data() {
    return {
      store: searchAndFilter(),
      localSearchTerm: "",
      searchOptions: [
        { name: "Fraud Lab ID", value: "fraud_labs_id" },
        { name: "Order ID ", value: "order_id_hash" },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.searchDropDown.p-dropdown.p-component {
  border-radius: 0 !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-right: 0 !important;
  width: 160px;
  @media (max-width: 480px) {
    width: 100px;
  }
}
.search-container {
  display: flex;

  .search-inp {
    padding: 0 12px;
    border: 1px solid #d8d8d8;
    width: 200px;
    outline: none;
    @media (max-width: 410px) {
      width: 150px;
    }
  }

  .search-btn {
    padding: 11.5px 14.5px;
    background: #a81d1b;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
</style>

import { createRouter, createWebHistory } from "vue-router";

import DashboardTab from "@/views/DashboardTab.vue";
import TransactionListings from "@/views/TransactionListings.vue";
import SettingsTab from "@/views/SettingsTab.vue";
import ApiSettings from "@/views/ApiSettings.vue";

const routes = [
  { path: "/", redirect: "/flabspro" },
  { path: "/flabspro", component: DashboardTab },
  { path: "/transaction-listings", component: TransactionListings },
  { path: "/settings", component: SettingsTab },
  { path: "/api-key-settings", component: ApiSettings },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active-tab",
});

export default router;

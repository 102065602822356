<template>
  <section class="settings">
    <div class="settings-container">
      <p class="settings-header">Lexmodo Refund Settings</p>
      <div class="checkbox-container">
        <CheckboxPrime
          :input-style="{ width: ' 18px', height: '18px' }"
          v-model="autoRefundRejected"
          inputId="autoRefundRejected"
          :binary="true"
        />
        <label for="autoRefundRejected">
          Automatically refund order when rejected by fraudlabs.
        </label>
      </div>
      <div class="checkbox-container">
        <CheckboxPrime
          :input-style="{ width: ' 18px', height: '18px' }"
          v-model="autoRefundPending"
          inputId="autoRefundPending"
          :binary="true"
        />
        <label for="autoRefundPending">
          Automatically refund order when pending by fraudlabs.
        </label>
      </div>
    </div>
    <base-button @click="saveSettings" style="margin-top: 15px"
      >Save changes</base-button
    >
  </section>
  <notification-message v-if="visibleNotification">
    {{ message }}
  </notification-message>
  <confirm-message
    v-if="leavePageConfirmShow"
    @confirm-message="comfirmMessageActions"
  >
    <template #title>Unsaved changes</template>
    <template #body>
      If you leave this page, any unsaved changes will be lost
    </template>
  </confirm-message>
</template>

<script>
import emitter from "@/services/eventBus";
import settingsService from "@/services/settingsService";
import CheckboxPrime from "primevue/checkbox";
import ConfirmMessage from "@/components/UI/ConfirmMessage/ConfirmMessage.vue";

export default {
  components: { CheckboxPrime, ConfirmMessage },
  emits: ["getTitle"],

  mounted() {
    this.getSettings();
    this.$emit("getTitle", "Settings");
  },
  beforeRouteLeave(to, from, next) {
    if (this.inputsTouched) {
      this.leavePageConfirmShow = true;
      this.clickedRoute = to;
      next(false);
    } else {
      next(true);
    }
  },
  watch: {
    autoRefundPending() {
      this.inputsTouched = true;
    },
    autoRefundRejected() {
      this.inputsTouched = true;
    },
  },
  data() {
    return {
      message: "",
      inputsTouched: false,
      clickedRoute: "",
      leavePageConfirmShow: false,
      visibleNotification: false,
      autoRefundRejected: false,
      autoRefundPending: false,
    };
  },
  methods: {
    showNotification(message) {
      this.message = message;
      //show notification and hide it after 0.5 second
      this.visibleNotification = true;
      setTimeout(() => {
        this.visibleNotification = false;
      }, 500);
    },
    // This function is used to check whether the user want to leave page or want to stay.
    // the value is a boolean value which will be recieved as payload from emitted event "confirm-message"
    comfirmMessageActions(value) {
      if (value) {
        // confirm: head to the route that user clicked
        this.inputsTouched = false;
        this.$router.push(this.clickedRoute);
      } else {
        // reject: stay at same route, and reset data
        this.leavePageConfirmShow = false;
        this.clickedRoute = "";
      }
    },
    getSettings() {
      settingsService
        .getPluginSettings()
        .then((result) => {
          // console.log(result);
          console.log(result.original.details);
          if (Object.keys(result.original.details).length === 0) {
            return;
          }
          if (result.original.status === "success") {
            this.autoRefundPending = Boolean(
              result.original.details.pending_auto_refund
            );
            this.autoRefundRejected = Boolean(
              result.original.details.rejected_auto_refund
            );
          }
        })
        .then(() => {
          this.inputsTouched = false;
        });
    },
    saveSettings() {
      if (this.inputsTouched) {
        settingsService
          .savePluginSettings(this.autoRefundRejected, this.autoRefundPending)
          .then((result) => {
            if (result.original.status === "success") {
              // if user turned on both auto reject and auto pending
              // emit event to hide warning icon from settings in navbar
              if (this.autoRefundPending && this.autoRefundRejected) {
                emitter.emit("hide-refund-warning");
              } else {
                emitter.emit("show-refund-warning");
              }
              this.showNotification("Successfully Saved");
              this.inputsTouched = false;
            }
          });
      } else if (!this.visibleNotification) {
        this.showNotification("All changes are saved!");
      }
    },
  },
};
</script>

<style scoped lang="scss">
section.settings {
  padding: 25px 27px;
}
.settings-container {
  padding-bottom: 20px;
  p.settings-header {
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 16px;
  }

  .inputs-container {
    width: 670px;
    display: grid;
    row-gap: 34px;
    column-gap: 18px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 31px;
  }

  .single {
    grid-area: 2 / 1 / 3 / 4;
  }

  .checkbox-container {
    padding: 2px 0;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>

<!-- 
  How to use?
  
  <ButtonsDropdown
    :buttonsLabels="['Reject', 'Approve']"
    :buttonsActions="['Reject', 'APPROVE']"
    :actionFunction="actionFunction"
    :recordId="data.user_order_id"
  />

  - buttonsLabels: is an Array which will be looped to show number of button.
  - buttonsAction: is an Array of paramerter that will be passed to actionFunction,
    should have same order of elements as in buttonsLabels.
  - actionFunction will do an action based on sent parameters.
  - recordId is the id of the record that action function will handle.

 -->
<template>
  <div style="position: relative">
    <button class="actions-btn" style="" @click="toggleDropdown">
      <span>Action</span
      ><i style="font-size: 20px" class="pi pi-angle-down"></i>
    </button>
    <div v-if="visibleDropdown" class="dropdown">
      <button
        v-for="(buttonLabel, index) in buttonsLabels"
        :key="index"
        @click="
          actionFunction(buttonsActions[index], recordId);
          visibleDropdown = false;
        "
      >
        {{ buttonLabel }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visibleDropdown: false,
    };
  },
  props: ["buttonsLabels", "buttonsActions", "actionFunction", "recordId"],
  methods: {
    toggleDropdown() {
      this.visibleDropdown = !this.visibleDropdown;
    },
  },
};
</script>

<style scoped lang="scss">
.actions-btn {
  width: 107px;
  padding: 5.5px 13px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 13px;
  border: 1px solid #cccccc;
  border-radius: 8px;
}

.dropdown {
  position: absolute;
  margin-top: 5px;
  top: 100%;
  z-index: 1;
  gap: 7px;
  padding: 11px;
  width: 107px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 9px 6px 24px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #cccccc;
}
</style>

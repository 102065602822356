<template>
  <svg
    width="60"
    height="71"
    viewBox="0 0 60 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M59.5 56.6764C45.6203 62.8801 31 65.7046 15 65.7046C11.4801 51.7542 3.59441 27.5876 0.0740369 13.6374C-0.282213 12.2229 0.681537 10.7596 2.22654 10.3671C15.7093 6.94343 29.1924 3.52072 42.6753 0.0975061C44.2215 -0.294972 45.7628 0.532617 46.1203 1.94704C50.1204 17.8052 55.4991 40.8184 59.5 56.6764Z"
      fill="white"
    />
    <path
      d="M39.2958 12.0341L10.8591 19.2539C10.3573 19.3813 9.84727 19.0765 9.72027 18.5729C9.59327 18.0693 9.89715 17.5578 10.3991 17.4304L38.8358 10.2106C39.3377 10.0832 39.8477 10.388 39.9747 10.8916C40.1017 11.3952 39.7977 11.9067 39.2958 12.0341Z"
      fill="#07D09E"
    />
    <path
      d="M41.0438 18.9618L12.6072 26.1816C12.1053 26.309 11.5953 26.0042 11.4683 25.5006C11.3413 24.9971 11.6452 24.4856 12.1472 24.3582L40.5838 17.1383C41.0857 17.0109 41.5957 17.3158 41.7227 17.8193C41.8497 18.3229 41.5457 18.8344 41.0438 18.9618Z"
      fill="#07D09E"
    />
    <path
      d="M42.8759 26.2255L14.4392 33.4453C13.9374 33.5727 13.4274 33.2679 13.3004 32.7643C13.1734 32.2607 13.4772 31.7492 13.9792 31.6218L42.4159 24.402C42.9179 24.2746 43.4277 24.5794 43.5547 25.083C43.6817 25.5866 43.3777 26.0979 42.8759 26.2255Z"
      fill="#07D09E"
    />
    <path
      d="M44.4032 32.2743L15.9666 39.4941C15.4647 39.6215 14.9547 39.3167 14.8277 38.8131C14.7007 38.3096 15.0046 37.7981 15.5066 37.6707L43.9432 30.4508C44.4451 30.3234 44.9551 30.6283 45.0821 31.1318C45.2091 31.6354 44.9052 32.1469 44.4032 32.2743Z"
      fill="#07D09E"
    />
    <path
      d="M46.8721 42.0594L33.906 45.3515C33.404 45.4789 32.8941 45.1741 32.7671 44.6705C32.6401 44.1669 32.944 43.6554 33.446 43.528L46.4122 40.236C46.914 40.1086 47.424 40.4134 47.551 40.917C47.678 41.4206 47.374 41.932 46.8721 42.0594Z"
      fill="#07D09E"
    />
    <path
      d="M48.3233 47.8135L35.3572 51.1054C34.8553 51.2328 34.3453 50.928 34.2183 50.4244C34.0913 49.9208 34.3952 49.4093 34.8972 49.2819L47.8633 45.9899C48.3652 45.8625 48.8752 46.1673 49.0022 46.6709C49.1292 47.1746 48.8252 47.686 48.3233 47.8135Z"
      fill="#07D09E"
    />
    <path
      d="M32.5398 51.4786C34.5689 59.5208 29.6972 67.7202 21.68 69.7556C13.663 71.791 5.4892 66.904 3.46015 58.8616C1.43111 50.8193 6.30305 42.6201 14.32 40.5847C22.337 38.5492 30.5108 43.4363 32.5398 51.4786Z"
      fill="#36B990"
    />
    <path
      d="M17.3701 60.7641C17.0413 60.8475 16.6809 60.8052 16.3666 60.6173L10.3577 57.0245C9.72918 56.6486 9.52337 55.8329 9.89789 55.2022C10.2726 54.5719 11.0859 54.3654 11.7143 54.7411L16.5852 57.6536L22.8142 47.1701C23.1889 46.5398 24.0022 46.3333 24.6306 46.709C25.2592 47.0849 25.465 47.9006 25.0904 48.5313L18.1831 60.1564C17.9958 60.4713 17.6988 60.6806 17.3701 60.7641Z"
      fill="white"
    />
  </svg>
</template>

import { defineStore } from 'pinia';

export const searchAndFilter = defineStore('searchAndFilter', {
  state: () => {
    return {
      //state of the word which will be searched
      searchTerm: '',
      //state of the field that will be searched ex: (fraud lab id or order id hash)
      searchBy: 'fraud_labs_id',
      filterOption: 'none',

      // STATE OF FILTER BY DATE
      filterByDate: '',
      startDate: '',
      endDate: '',
      rangeDateOption: 'dateIs',
    };
  },
  getters: {
    filterLazyParam() {
      if (this.filterByDate) {
        console.log(this.filterByDate, 'this.filterByDate');
        switch (this.filterOption) {
          case 'dateIs':
            return {
              created_at: {
                operator: 'FilterOperator.AND',
                constraints: [
                  {
                    value: this.filterByDate,
                    matchMode: this.rangeDateOption,
                  },
                ],
              },
            };
          case 'dateIsNot':
            return {
              created_at: {
                operator: 'FilterOperator.AND',
                constraints: [
                  {
                    value: this.filterByDate,
                    matchMode: this.rangeDateOption,
                  },
                ],
              },
            };
          case 'dateBefore':
            return {
              created_at: {
                operator: 'FilterOperator.AND',
                constraints: [
                  {
                    value: this.filterByDate,
                    matchMode: this.rangeDateOption,
                  },
                ],
              },
            };
          case 'dateAfter':
            return {
              created_at: {
                operator: 'FilterOperator.AND',
                constraints: [
                  {
                    value: this.filterByDate,
                    matchMode: this.rangeDateOption,
                  },
                ],
              },
            };

          default:
            break;
        }
      }

      if (!this.searchTerm) {
        switch (this.filterOption) {
          case 'none':
            return {
              fraud_lab_order_status: { value: '', matchMode: 'equal' },
              created_at: {
                operator: 'FilterOperator.AND',
                constraints: [
                  {
                    value: this.startDate,
                    matchMode: 'dateAfter',
                  },
                  {
                    value: this.endDate,
                    matchMode: 'dateBefore',
                  },
                ],
              },
            };
          case 'approved':
            return {
              fraud_lab_order_status: { value: 'APPROVE', matchMode: 'equal' },
              created_at: {
                operator: 'FilterOperator.AND',
                constraints: [
                  {
                    value: this.startDate,
                    matchMode: 'dateAfter',
                  },
                  {
                    value: this.endDate,
                    matchMode: 'dateBefore',
                  },
                ],
              },
            };
          case 'rejected':
            return {
              fraud_lab_order_status: { value: 'REJECT', matchMode: 'equal' },
              created_at: {
                operator: 'FilterOperator.AND',
                constraints: [
                  {
                    value: this.startDate,
                    matchMode: 'dateAfter',
                  },
                  {
                    value: this.endDate,
                    matchMode: 'dateBefore',
                  },
                ],
              },
            };
          case 'pending':
            return {
              fraud_lab_order_status: { value: 'REVIEW', matchMode: 'equal' },
              created_at: {
                operator: 'FilterOperator.AND',
                constraints: [
                  {
                    value: this.startDate,
                    matchMode: 'dateAfter',
                  },
                  {
                    value: this.endDate,
                    matchMode: 'dateBefore',
                  },
                ],
              },
            };

          default:
            break;
        }
      } else {
        /* eslint-disable */
        // return;
        switch (this.filterOption) {
          case 'none':
            return {
              fraud_lab_order_status: { value: '', matchMode: 'equal' },
              [this.searchBy]: {
                value: this.searchTerm,
                matchMode: 'contains',
              },
              created_at: {
                operator: 'FilterOperator.AND',
                constraints: [
                  {
                    value: this.startDate,
                    matchMode: 'dateAfter',
                  },
                  {
                    value: this.endDate,
                    matchMode: 'dateBefore',
                  },
                ],
              },
            };
          case 'approved':
            return {
              fraud_lab_order_status: { value: 'APPROVE', matchMode: 'equal' },
              [this.searchBy]: {
                value: this.searchTerm,
                matchMode: 'contains',
              },
              created_at: {
                operator: 'FilterOperator.AND',
                constraints: [
                  {
                    value: this.startDate,
                    matchMode: 'dateAfter',
                  },
                  {
                    value: this.endDate,
                    matchMode: 'dateBefore',
                  },
                ],
              },
            };
          case 'rejected':
            return {
              fraud_lab_order_status: { value: 'REJECT', matchMode: 'equal' },
              [this.searchBy]: {
                value: this.searchTerm,
                matchMode: 'contains',
              },
              created_at: {
                operator: 'FilterOperator.AND',
                constraints: [
                  {
                    value: this.startDate,
                    matchMode: 'dateAfter',
                  },
                  {
                    value: this.endDate,
                    matchMode: 'dateBefore',
                  },
                ],
              },
            };
          case 'pending':
            return {
              fraud_lab_order_status: { value: 'REVIEW', matchMode: 'equal' },
              [this.searchBy]: {
                value: this.searchTerm,
                matchMode: 'contains',
              },
              created_at: {
                operator: 'FilterOperator.AND',
                constraints: [
                  {
                    value: this.startDate,
                    matchMode: 'dateAfter',
                  },
                  {
                    value: this.endDate,
                    matchMode: 'dateBefore',
                  },
                ],
              },
            };

          default:
            break;
        }
      }
    },
  },
  actions: {
    setSearchTerm(value) {
      this.searchTerm = value;
    },
    setFilterOption(value) {
      this.filterOption = value;
    },
    setRangeFilter(value) {
      this.filterByDate = value;
    },
  },
});

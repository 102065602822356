<template>
  <div>
    <label :for="id">
      <slot></slot>
    </label>
    <input
      disabled
      :type="inputType || 'text'"
      :id="id"
      :name="id"
      :style="{ height: inputHeight + 'px' }"
      @input="$emit('update:modelValue', $event.target.value)"
      :value="modelValue || defaultValue"
    />
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: ["modelValue", "id", "inputHeight", "inputType", "defaultValue"],
};
</script>

<style scoped>
div {
  position: relative;
}

p {
  position: absolute;
  bottom: 10.5px;
  right: 24px;
}

img {
  cursor: pointer;
  position: absolute;
  bottom: 13px;
  right: 24px;
}

label {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 3px;
}

input {
  padding-left: 20px;
  padding-right: 50px;
  height: 45px;
  width: 100%;
  background-color: #fbfbfb;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #202223;
}

input:disabled {
  color: #919191;
}

input:focus {
  outline: 1px solid #1575bf;
}
</style>

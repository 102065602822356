<template>
  <div class="backdrop">
    <div class="popup-container" @submit.prevent="addCustomer">
      <div class="header">
        <h5>Order Details</h5>
        <CloseSignSvg @click.prevent="$emit('hideModal')" />
      </div>
      <div class="sub-header">
        <p>Transaction Id</p>
        <p class="trans-id">#{{ fraudlabsId }}</p>
      </div>
      <div class="grid-3">
        <label-input :defaultValue="'$' + orderDetailsData.amount.toFixed(2)"
          >Total</label-input
        >
        <label-input :defaultValue="orderDetailsData.distance_in_km"
          >Distance In Km</label-input
        >
        <label-input :defaultValue="orderDetailsData.high_risk_country"
          >High Risk Country</label-input
        >
        <label-input :defaultValue="orderDetailsData.isp_name"
          >Isp Name</label-input
        >
        <label-input :defaultValue="orderDetailsData.distance_in_mile"
          >Distance In Mile</label-input
        >
        <label-input :defaultValue="orderDetailsData.free_email"
          >Free Email</label-input
        >
        <label-input :defaultValue="orderDetailsData.is_credit_card_blacklist"
          >Is Credit Card Blacklist</label-input
        >
        <label-input :defaultValue="orderDetailsData.is_bin_found"
          >Is Bin Found</label-input
        >
        <label-input :defaultValue="orderDetailsData.is_address_ship_forward"
          >Is Address Ship Forward</label-input
        >
        <label-input :defaultValue="orderDetailsData.is_email_blacklist"
          >Is Email Blacklist</label-input
        >
      </div>

      <hr />

      <div class="grid-3">
        <label-input :defaultValue="orderDetailsData.ip_domain"
          >Ip Domain</label-input
        >
        <label-input :defaultValue="orderDetailsData.ip_latitude"
          >Ip Latitude</label-input
        >
        <label-input :defaultValue="orderDetailsData.ip_longitude"
          >Ip Longitude</label-input
        >
        <label-input :defaultValue="orderDetailsData.ip_domain"
          >Ip Domain Country</label-input
        >
        <label-input :defaultValue="orderDetailsData.ip_region"
          >Ip Region</label-input
        >
        <label-input :defaultValue="orderDetailsData.ip_usage_type"
          >Ip Usage Type</label-input
        >
      </div>

      <div class="actions">
        <base-button @click.prevent="$emit('hideModal')" class="small"
          >Close</base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import CloseSignSvg from "@/assets/icons/CloseSignSvg.vue";

export default {
  emits: ["hideModal"],
  components: { CloseSignSvg },
  props: ["orderDetailsData", "fraudlabsId"],
};
</script>

<style scoped>
.backdrop {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.35);
}

.popup-container {
  padding: 26px 30px;
  margin: 2.5% 20% 10% auto;
  background-color: #fff;
  width: 1017px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index: 1005;

  position: fixed;
  top: 50px; /* replace 50px with the amount of top padding you want to keep */
  left: 50%;
  transform: translateX(-50%);
  overflow-y: scroll;
  height: 80%;
}

.popup-container input {
  height: 40px;
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  padding-left: 20px;
}

.popup-container input:focus {
  border: 2px solid #a8a8a8;
  outline: none;
}

.popup-container h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 36px;
}

div.header svg {
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
}

.sub-header {
  color: #a81d1b;
  margin-bottom: 28px;
}
.trans-id {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}
.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 24px;
  column-gap: 22px;
}

hr {
  margin-top: 20px;
  margin-bottom: 24px;
}

.actions {
  padding-top: 57px;
  display: flex;
  justify-content: flex-end;
}
</style>

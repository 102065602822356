<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 8C2 4.691 4.691 2 8 2C11.309 2 14 4.691 14 8C14 11.309 11.309 14 8 14C4.691 14 2 11.309 2 8ZM19.707 18.293L14.312 12.897C15.4058 11.499 16.0001 9.77505 16 8C16 3.589 12.411 0 8 0C3.589 0 0 3.589 0 8C0 12.411 3.589 16 8 16C9.77495 15.9995 11.4987 15.4053 12.897 14.312L18.293 19.707C18.3857 19.8002 18.4958 19.8741 18.6171 19.9246C18.7385 19.9751 18.8686 20.001 19 20.001C19.1314 20.001 19.2615 19.9751 19.3829 19.9246C19.5042 19.8741 19.6143 19.8002 19.707 19.707C19.7999 19.6142 19.8737 19.504 19.924 19.3827C19.9743 19.2614 20.0002 19.1313 20.0002 19C20.0002 18.8687 19.9743 18.7386 19.924 18.6173C19.8737 18.496 19.7999 18.3858 19.707 18.293Z"
      fill="white"
    />
  </svg>
</template>

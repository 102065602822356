import axios from "axios";
function subtractDays(date, days) {
  date.setDate(date.getDate() - days);
  return date;
}

export default {
  getHeader() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("auth_fraudlabs")}`,
    };
  },
  async getStatistics(date = {}) {
    return axios
      .post("https://fraudlabs-api.numinix.com/api/SummaryReports", date, {
        headers: this.getHeader(),
      })
      .then((res) => res.data);
  },
  async getLicenseData() {
    return axios
      .post(
        "https://fraudlabs-api.numinix.com/api/LicenseOverView",
        {},
        {
          headers: this.getHeader(),
        }
      )
      .then((res) => res.data);
  },
  async getDailyData() {
    return axios
      .post(
        "https://fraudlabs-api.numinix.com/api/DailyTransaction",
        {},
        {
          headers: this.getHeader(),
        }
      )
      .then((res) => res.data);
  },
  async getMonthlyData() {
    let toDate = new Date();
    let fromDate = subtractDays(new Date(), 30);
    let toUnix = Math.floor(toDate.getTime() / 1000);
    let fromUnix = Math.floor(fromDate.getTime() / 1000);
    return axios
      .post(
        "https://fraudlabs-api.numinix.com/api/MonthlyTransaction",
        { from: fromUnix, to: toUnix },
        {
          headers: this.getHeader(),
        }
      )
      .then((res) => res.data);
  },
  async syncOrders() {
    return axios
      .post(
        "https://fraudlabs-api.numinix.com/api/SyncOrders",
        {},
        { headers: this.getHeader() }
      )
      .then((res) => res.data);
  },
};

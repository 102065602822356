<template>
  <div class="dashboard">
    <div class="upper-part">
      <summary-stats></summary-stats>
      <license-overview></license-overview>
    </div>
    <transaction-overview-tables></transaction-overview-tables>
  </div>
</template>

<script>
import LicenseOverview from "@/components/DashboardComponents/LicenseOverview/LicenseOverview.vue";
import SummaryStats from "@/components/DashboardComponents/SummaryStats/SummaryStats.vue";
import TransactionOverviewTables from "@/components/DashboardComponents/TransactionOverviewTables/TransactionOverviewTables.vue";

export default {
  emits: ["getTitle"],
  components: {
    SummaryStats,
    LicenseOverview,
    TransactionOverviewTables,
  },
  mounted() {
    this.$emit("getTitle", "FraudLabs Pro");
  },
};
</script>

<style scoped>
.dashboard {
  padding: 27px;
  padding-bottom: 80px;
}

.upper-part {
  display: flex;
  gap: 35px;

  @media (max-width: 720px) {
    flex-direction: column;
  }
}
</style>

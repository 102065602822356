<template>
  <div class="empty">
    <SvgIcons name="emptyListingIcon" />
    <!-- <h5>No transaction listing available</h5>
    <p>
      Add carries and configure shipping methods to start shipping with
      EasyPost.
    </p> -->
  </div>
</template>

<script>
import SvgIcons from "@/assets/icons/SvgIcons.vue";
export default {
  components: { SvgIcons },
};
</script>

<style scoped lang="scss">
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  .svg {
    margin-bottom: 30px;
  }
  h5 {
    margin-bottom: 13px;
    font-size: 18px;
    line-height: 28px;
  }
  p {
    margin-bottom: 34px;
    color: #6d6d6d;
    max-width: 418px;
  }
}
</style>

<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.0991 9.00017C13.0991 9.23057 13.0109 9.46097 12.8354 9.63647L8.33535 14.1365C7.98345 14.4884 7.41465 14.4884 7.06275 14.1365C6.71085 13.7846 6.71085 13.2158 7.06275 12.8639L10.9265 9.00017L7.06275 5.13647C6.71085 4.78457 6.71085 4.21577 7.06275 3.86387C7.41465 3.51197 7.98345 3.51197 8.33535 3.86387L12.8354 8.36387C13.0109 8.53937 13.0991 8.76977 13.0991 9.00017"
      fill="#6D6D6D"
    />
  </svg>
</template>

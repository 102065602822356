<template>
  <div style="padding-top: 13px">
    <DataTable
      v-if="transactionData.length > 0"
      class="orders-table"
      scrollable
      :loading="loading"
      :lazy="true"
      :totalRecords="total"
      sort-field="order_id_hash"
      :sort-order="-1"
      @page="onPage($event)"
      @sort="onPage($event)"
      @filter="onPage($event)"
      :value="transactionData"
      v-model:filters="store.filterLazyParam"
      dataKey="user_order_id"
      :paginator="true"
      :rows="10"
      paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      responsiveLayout="scroll"
      ref="dataTable"
      :rowClass="getRowStyleClass"
    >
      <Column
        class="content-center"
        field="fraud_lab_order_status"
        header="Fraud Lab Status"
      >
        <template #body="{ data }">
          <span
            class="status-cell approved"
            v-if="data.fraud_lab_order_status === 'APPROVE'"
            >Approved</span
          >
          <span
            class="status-cell rejected"
            v-if="data.fraud_lab_order_status === 'REJECT'"
            >Rejected</span
          >
          <span
            class="status-cell pending"
            v-if="data.fraud_lab_order_status === 'REVIEW'"
            >Pending</span
          >
        </template>
      </Column>
      <Column class="content-center" field="" header="Action Required">
        <template #body="{ data }">
          <p v-if="data?.lexmodo_status === 0">
            <i
              v-tooltip.right="'Action needed in Lexmodo actions'"
              class="pi pi-exclamation-triangle"
              style="color: rgb(148, 141, 16)"
            ></i>
          </p>
          <p v-else>
            <i
              v-tooltip.right="'No action needed.'"
              class="pi pi-check-circle"
              style="color: green"
            ></i>
          </p>
        </template>
      </Column>
      <Column
        class="content-center"
        field="user_store_id"
        header="Fraud Lab Store"
      ></Column>
      <Column
        class="content-center"
        field="fraud_labs_id"
        header="Fraud Lab Id"
      ></Column>
      <Column class="content-center" field="order_id_hash" header="Order Id">
        <template #body="{ data }">
          <a
            class="order-link"
            :href="`https://admin.lexmodo.com/orders/${data.user_order_id}`"
            target="_blank"
          >
            <span>{{ data.order_id_hash }}</span>
          </a>
        </template>
      </Column>
      <Column class="content-center" field="" header="Order Details">
        <template #body="{ data }">
          <button
            class="show-btn"
            @click="showDetailsModal(data.fraud_labs_id, data.order_data)"
          >
            <SvgIcons name="eyeIcon" />
            <span>Show</span>
          </button>
        </template>
      </Column>
      <Column class="content-center" field="" header="Fraudlabs Actions">
        <template #body="{ data }">
          <ButtonsDropdown
            :buttonsLabels="['Reject', 'Approve']"
            :buttonsActions="['REJECT', 'APPROVE']"
            :actionFunction="fraudlabsActionFunction"
            :recordId="data.user_order_id"
          />
        </template>
      </Column>
      <Column class="content-center" field="" header="Lexmodo Actions">
        <template #body="{ data }">
          <ButtonsDropdown
            v-if="data?.lexmodo_status === 0"
            :buttonsLabels="['Refund']"
            :buttonsActions="['REFUND']"
            :actionFunction="lexmodoRefundFunction"
            :recordId="data.user_order_id"
          />
          <span v-else class="status-cell refunded">Refunded</span>
        </template>
      </Column>
    </DataTable>
    <EmptyListing v-else />
  </div>
  <OrderDetailsModal
    v-if="detailsModelVisible"
    :orderDetailsData="orderDetailsData"
    :fraudlabsId="orderDetailFraudId"
    @hide-modal="hideDetailsModal"
  ></OrderDetailsModal>
</template>

<script>
import { defineComponent, ref, watch, onMounted, onUnmounted } from "vue";
import ButtonsDropdown from "@/components/UI/ButtonsDropdown/ButtonsDropdown.vue";
import SvgIcons from "@/assets/icons/SvgIcons.vue";
import OrderDetailsModal from "@/components/OrderDetailsModal/OrderDetailsModal.vue";
import EmptyListing from "@/components/UI/EmptyListing/EmptyListing.vue";
import listingsService from "@/services/listingsService";
import { searchAndFilter } from "@/stores/searchAndFilterListing";
import emitter from "@/services/eventBus";

export default defineComponent({
  components: {
    ButtonsDropdown,
    OrderDetailsModal,
    EmptyListing,
    SvgIcons,
  },
  emits: ["getTitle"],
  setup(props, { emit }) {
    const store = searchAndFilter();
    const filterOption = ref(store);
    const detailsModelVisible = ref(false);
    const orderDetailsData = ref({});
    const orderDetailFraudId = ref("");
    const lazyParams = ref({
      first: 0,
      sortField: "order_id_hash",
      sortOrder: -1,
      filters: {},
    });
    const filters = ref({
      fraud_lab_order_status: {
        value: "APPROVE",
        matchMode: "equal",
      },
      created_at: {
        operator: "FilterOperator.AND",
        constraints: [
          {
            value: "",
            matchMode: "dateIs",
          },
        ],
      },
    });

    // const createdAt = ref({
    //   operator: "FilterOperator.AND",
    //   constraints: [{ value: "2024-01-13", matchMode: "dateAfter" }],
    // });

    const total = ref(0);
    const error = ref(false);
    const loading = ref(true);
    const transactionData = ref([]);

    const loadLazyData = () => {
      loading.value = true;
      lazyParams.value.filters = store.filterLazyParam;
      listingsService.getAllOrders(lazyParams.value).then((data) => {
        if (data.original.success) {
          total.value = data.original.payload.total;
          transactionData.value = data.original.payload.data;

          // console.log(transactionData.value);
          console.log(lazyParams.value, "lazyParams.value");

          loading.value = false;
        } else if (data.original.message === "there is no customers") {
          total.value = 0;
          transactionData.value = [];
          loading.value = false;
        } else {
          loading.value = false;
          error.value = true;
        }
      });
    };

    const onPage = (data) => {
      lazyParams.value = data;
      lazyParams.value.filters = store.filterLazyParam;
      loadLazyData();
    };

    const onFilter = () => {
      lazyParams.value.filters = store.filterLazyParam;
      loadLazyData();
    };

    const showDetailsModal = (id, data) => {
      orderDetailsData.value = JSON.parse(data);
      orderDetailFraudId.value = id;
      detailsModelVisible.value = true;
    };

    const hideDetailsModal = () => {
      orderDetailsData.value = {};
      orderDetailFraudId.value = "";
      detailsModelVisible.value = false;
    };

    // function which will update order status => approved/rejected
    const fraudlabsActionFunction = (status, recordId) => {
      listingsService.orderStatusUpdate(status, recordId).then((result) => {
        console.log(result);
        loadLazyData();
      });
    };

    // function which will refund order in store
    const lexmodoRefundFunction = (status, recordId) => {
      listingsService.refundOrderById(recordId).then((result) => {
        console.log(result);
        loadLazyData();
      });
    };

    //function which will check if action in required and return colored background based on row data
    const getRowStyleClass = (rowData) => {
      if (rowData?.lexmodo_status === 0) {
        return "action-req"; // CSS class to highlight the row
      }
      return ""; // no additional CSS class
    };

    onMounted(() => {
      emit("getTitle", "Transaction Listing");
      loadLazyData();
      // Next line will act as listner to event "clear-filters" when triggered by another component
      emitter.on("clear-filters", () => {
        store.filterOption = "none";
        store.searchTerm = "";
        loadLazyData();
      });
      emitter.on("refresh-datatable", () => {
        loadLazyData();
      });
    });
    onUnmounted(() => {
      store.filterOption = "none";
      store.searchTerm = "";
    });
    watch(
      () => store.filterLazyParam,
      () => {
        lazyParams.value.filters = store.filterLazyParam;
        loadLazyData();
      }
    );

    return {
      store,
      filterOption,
      detailsModelVisible,
      orderDetailsData,
      orderDetailFraudId,
      lazyParams,
      filters,
      total,
      error,
      loading,
      transactionData,
      onPage,
      onFilter,
      showDetailsModal,
      hideDetailsModal,
      fraudlabsActionFunction,
      lexmodoRefundFunction,
      loadLazyData,
      getRowStyleClass,
    };
  },
});
</script>

<style scoped lang="scss">
.p-datatable {
  .order-link:hover {
    color: rgb(0, 128, 255);
  }
  .status-cell {
    border-radius: 100px;
    padding: 2px 10px;
    font-size: 13px;
    line-height: 20px;
  }
  .status-cell.approved {
    color: #00795b;
    background-color: #cefff2;
  }
  .status-cell.pending {
    color: #202223;
    background-color: #ffe9be;
  }
  .status-cell.rejected {
    color: #a81b00;
    background-color: #fed3d1;
  }
  .status-cell.refunded {
    text-transform: uppercase;
    color: #004982;
    background-color: #9fd5ff;
  }
  :deep(.p-datatable-thead > tr > th) {
    color: #fff;
    background: #a81d1b;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
}
.p-component {
  font-family: "Plus Jakarta Sans", sans-serif;
}

.orders-table {
  :deep(.p-checkbox-box) {
    width: 18px;
    height: 18px;
  }
  /* Paginator styles */
  :deep(div.p-paginator) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  :deep(button.p-paginator-prev),
  :deep(button.p-paginator-next),
  :deep(button.p-paginator-first),
  :deep(button.p-paginator-last) {
    width: 40px;
    height: 40px;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
  }
  :deep(span.p-paginator-current) {
    cursor: default;
    font-weight: 400;
    font-size: 14px;
    color: #202223;
  }
  :deep(.action-req) {
    // color: red;
    background-color: rgba(0, 0, 0, 0.03) !important;
    // border-left: 5px solid #e5ff00;
  }
  :deep(.content-center) {
    text-align: start !important;
  }
}

//style show details column
.show-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>

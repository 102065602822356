<template>
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.5 5.5C59.6046 5.5 60.5 6.39543 60.5 7.5V47.5C60.5 48.6046 59.6046 49.5 58.5 49.5H2C0.895432 49.5 0 48.6046 0 47.5V2C0 0.89543 0.895431 0 2 0H20.7639C21.5215 0 22.214 0.428005 22.5528 1.10557L24.1972 4.39443C24.536 5.07199 25.2285 5.5 25.9861 5.5H58.5Z"
      fill="#C83E25"
    />
    <path
      d="M2.36092 49.5C1.12504 49.5 0.184956 48.3903 0.388133 47.1712L5.22147 18.1712C5.3822 17.2068 6.21658 16.5 7.19425 16.5H63.6391C64.875 16.5 65.815 17.6097 65.6119 18.8288L60.7785 47.8288C60.6178 48.7932 59.7834 49.5 58.8057 49.5H2.36092Z"
      fill="#FFAD9E"
    />
    <path
      d="M31.0745 62.9751C38.6686 62.9751 44.8248 56.8189 44.8248 49.2249C44.8248 41.6308 38.6686 35.4746 31.0745 35.4746C23.4804 35.4746 17.3242 41.6308 17.3242 49.2249C17.3242 56.8189 23.4804 62.9751 31.0745 62.9751Z"
      fill="#FF684D"
    />
    <path
      d="M36.6305 46.4456C37.3972 45.6786 37.3971 44.4353 36.6302 43.6684C35.8631 42.9013 34.6193 42.9014 33.8523 43.6687L32.489 45.0327C31.708 45.8141 30.4414 45.8142 29.6602 45.033L28.2952 43.6681C27.5282 42.9011 26.2847 42.9011 25.5177 43.6681C24.7507 44.4351 24.7507 45.6786 25.5177 46.4456L26.883 47.8109C27.664 48.592 27.664 49.8583 26.883 50.6394L25.5177 52.0047C24.7507 52.7717 24.7507 54.0152 25.5177 54.7822C26.2847 55.5492 27.5282 55.5492 28.2952 54.7822L29.6602 53.4172C30.4414 52.6361 31.708 52.6362 32.489 53.4176L33.8523 54.7816C34.6193 55.5489 35.8631 55.549 36.6302 54.7819C37.3971 54.015 37.3972 52.7717 36.6305 52.0047L35.2655 50.639C34.4849 49.8581 34.4849 48.5922 35.2655 47.8113L36.6305 46.4456Z"
      fill="white"
    />
    <path
      d="M25.2361 0C23.7493 0 22.7823 1.56463 23.4472 2.89443L24.1972 4.39443C24.536 5.072 25.2285 5.5 25.9861 5.5H38.0139C39.5007 5.5 40.4677 3.93537 39.8028 2.60557L39.0528 1.10557C38.714 0.428005 38.0215 0 37.2639 0H25.2361Z"
      fill="#FF684D"
    />
  </svg>
</template>

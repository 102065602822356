import axios from "axios";

export default {
  getHeader() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("auth_fraudlabs")}`,
    };
  },
  async getPluginSettings() {
    return axios
      .get("https://fraudlabs-api.numinix.com/api/showStoreConfiguration", {
        headers: this.getHeader(),
      })
      .then((res) => res.data)
      .catch((err) => console.log(err));
  },
  async savePluginSettings(rejected_auto_refund, pending_auto_refund) {
    return axios
      .post(
        "https://fraudlabs-api.numinix.com/api/setStoreConfiguration",
        {
          rejected_auto_refund,
          pending_auto_refund,
        },
        { headers: this.getHeader() }
      )
      .then((res) => res.data);
  },
  async getPluginApiKey() {
    return axios
      .get("https://fraudlabs-api.numinix.com/api/confirmFraudLabsKey", {
        headers: this.getHeader(),
      })
      .then((res) => res.data);
  },
  async savePluginApiKey(fraud_lab_key) {
    return axios
      .post(
        "https://fraudlabs-api.numinix.com/api/handleFraudLabKey",
        {
          fraud_lab_key,
        },
        { headers: this.getHeader() }
      )
      .then((res) => res.data);
  },
};

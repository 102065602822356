<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0967 1.66136C11.2148 0.779546 9.78515 0.779547 8.90333 1.66136L2.16136 8.40334C1.27955 9.28515 1.27955 10.7149 2.16136 11.5967L8.90334 18.3386C9.78515 19.2205 11.2149 19.2205 12.0967 18.3386L18.8386 11.5967C19.7205 10.7148 19.7205 9.28515 18.8386 8.40333L12.0967 1.66136ZM9.59673 6.38716C9.59673 5.88833 10.0011 5.48395 10.4999 5.48395C10.9988 5.48395 11.4032 5.88833 11.4032 6.38716V10C11.4032 10.4988 10.9988 10.9032 10.4999 10.9032C10.0011 10.9032 9.59673 10.4988 9.59673 10V6.38716ZM11.4032 13.6128C11.4032 14.1117 10.9988 14.516 10.4999 14.516C10.0011 14.516 9.59673 14.1117 9.59673 13.6128C9.59673 13.114 10.0011 12.7096 10.4999 12.7096C10.9988 12.7096 11.4032 13.114 11.4032 13.6128Z"
      fill="#FF684D"
    />
  </svg>
</template>

<!-- 
  This Upper Bar Should be Reusable for all plugins:
  1- Logo Svg Should be inserted throgh slots
  2- Title Should be 
 -->
<template>
  <div class="upperBar">
    <div class="row">
      <div class="title-upper">
        <slot>
          <!-- Logo -->
        </slot>
        <h4>{{ title }}</h4>
      </div>
      <div class="left-space">
        <button style="outline: none">
          <svg
            width="18"
            height="4"
            viewBox="0 0 18 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="2" cy="2" r="2" fill="#6D6D6D" />
            <circle cx="9" cy="2" r="2" fill="#6D6D6D" />
            <circle cx="16" cy="2" r="2" fill="#6D6D6D" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PluginLogoSvg from "./PluginLogoSvg.vue";

/* eslint-disable */
export default {
  props: ["title"],
  components: { PluginLogoSvg },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.upperBar {
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 10px 10px;
  }

  .title-upper {
    display: flex;
    gap: 14px;
    align-items: center;
    h4 {
      color: #202223;
      font-weight: 300;
      font-size: 16px;
    }
  }
  .left-space {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-end;
    button {
      background: rgba(255, 255, 255, 0);
      border: none;
      cursor: pointer;
    }
  }
}
</style>

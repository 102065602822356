import axios from 'axios';

export default {
  getHeader() {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('auth_fraudlabs')}`,
    };
  },
  async refundOrderById(user_order_id) {
    return axios.post(
      `https://fraudlabs-api.numinix.com/api/LexmodoAction`,
      { user_order_id },
      { headers: this.getHeader() }
    );
  },
  async orderStatusUpdate(status, orderId) {
    return axios.post(
      `https://fraudlabs-api.numinix.com/api/ActionTransaction`,
      { user_order_id: orderId, status },
      { headers: this.getHeader() }
    );
  },
  async getAllOrders(params) {
    return axios
      .get(`https://fraudlabs-api.numinix.com/api/showDataToUser`, {
        params: {
          dt_params: JSON.stringify({
            ...params,
          }),
        },
        headers: this.getHeader(),
      })
      .then(res => {
        return res.data;
      })
      .catch(err => console.log(err));
  },
  deleteSelectedRecordsById(recordsArray = [], currentThis, recordsArrayName = '', loadDataFn) {
    let that = currentThis;
    let currentObject = this;
    recordsArray.forEach(function (row, index) {
      axios
        .post(
          'https://fraudlabs-api.numinix.com/api/DeleteTransaction',
          {
            user_order_id: row.user_order_id,
          },
          { headers: currentObject.getHeader() }
        )
        .then(() => {
          if (that[recordsArrayName].length - 1 === index) {
            loadDataFn();
            that[recordsArrayName] = [];
          }
        })
        .catch(err => console.log(err));
    });
  },
};

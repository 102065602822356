<template>
  <div class="summary-container">
    <div class="cards-header">
      <h3 class="stats-title">Summary</h3>
      <Dropdown
        @change="dateRangeSelect"
        :options="filterOptions"
        v-model="filterValue"
        optionLabel="name"
        optionValue="value"
        style="width: 150px; border: 1px solid #d8d8d8; border-radius: 8px"
      />
    </div>
    <div class="cards-container">
      <stats-card
        v-for="card in cardsArray"
        :class="card.customClass"
        :cardCount="card.cardCount"
        :cardTitle="card.cardTitle"
        :key="card.cardTitle"
      >
        <component :is="card.svgComponent" />
      </stats-card>
    </div>
  </div>
</template>

<script>
import dashboardService from "@/services/dashboardService";
import emitter from "@/services/eventBus";

import StatsCard from "@/components/UI/StatsCard/StatsCard.vue";
import { markRaw } from "vue";
import ApprovedSvg from "./icons/ApprovedSvg.vue";
import DeniedSvg from "./icons/DeniedSvg.vue";
import PendingSvg from "./icons/PendingSvg.vue";

export default {
  props: ["executeFunction"],
  mounted() {
    this.getStatisticsData();
    // this will listen to "refresh-data" event when triggered from another component
    emitter.on("refresh-data", () => {
      this.getStatisticsData();
    });
  },
  components: { StatsCard },
  data() {
    return {
      filterValue: "all_time",
      filterOptions: [
        { name: "Yesterday", value: 1 },
        { name: "Last 7 days", value: 7 },
        { name: "Last 30 days", value: 30 },
        { name: "Last 90 days", value: 90 },
        { name: "All time", value: "all_time" },
      ],
      date: null,
      cardsArray: [
        {
          cardCount: 0,
          cardTitle: "Approved",
          customClass: "approved-orders",
          svgComponent: markRaw(ApprovedSvg),
        },
        {
          cardCount: 0,
          cardTitle: "Denied",
          customClass: "denied-orders",
          svgComponent: markRaw(DeniedSvg),
        },
        {
          cardCount: 0,
          cardTitle: "Pending",
          customClass: "pending-orders",
          svgComponent: markRaw(PendingSvg),
        },
      ],
    };
  },
  methods: {
    dateRangeSelect(value) {
      if (value.value === "all_time") {
        this.getStatisticsData();
        return;
      }
      let toDate = new Date();
      let fromDate = this.subtractDays(new Date(), value.value);
      let toUnix = Math.floor(toDate.getTime() / 1000);
      let fromUnix = Math.floor(fromDate.getTime() / 1000);
      let dateFromTo = {
        from: `${fromUnix}`,
        to: `${toUnix}`,
      };
      this.getStatisticsData(dateFromTo);
    },
    getStatisticsData(date = {}) {
      dashboardService.getStatistics(date).then((result) => {
        if (result.original.success) {
          let {
            original: { data },
          } = result;

          this.cardsArray[0].cardCount = data.total_approve;
          this.cardsArray[1].cardCount = data.total_Denied;
          this.cardsArray[2].cardCount = data.total_Pending;
        } else {
          this.cardsArray[0].cardCount = 0;
          this.cardsArray[1].cardCount = 0;
          this.cardsArray[2].cardCount = 0;
        }
      });
    },
    subtractDays(date, days) {
      date.setDate(date.getDate() - days);
      return date;
    },
  },
};
</script>

<style scoped>
.summary-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.stats-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
}
.cards-header {
  display: flex;
  justify-content: space-between;
}
.cards-container {
  display: flex;
  gap: 20px;
}

@media only screen and (max-width: 1200px) {
  .cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 980px) {
  .cards-container {
    display: grid;
    grid-template-columns: 1fr;
  }

  .cards-header {
    flex-direction: column;
  }
}
</style>

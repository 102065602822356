<template>
  <svg
    width="68"
    height="69"
    viewBox="0 0 68 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8301_19942)">
      <path
        d="M35.1248 10.9375L33.4982 0H9.6343C6.04089 0 3.1875 2.97074 3.1875 6.61657V52.4066C3.1875 56.0524 6.04089 58.9629 9.6343 58.9629H32.2714L48.5799 33.907V16.0388L35.1248 10.9375Z"
        fill="#D6A03B"
      />
      <path
        d="M33.498 0.015625L48.5353 16.0393H38.5254C35.7455 16.0393 33.498 13.7588 33.498 10.938V0.015625Z"
        fill="#FFC453"
      />
      <path
        d="M13.6565 37.3581H32.9222C33.7353 37.3581 34.401 36.6834 34.401 35.858C34.401 35.0329 33.7353 34.3574 32.9222 34.3574H13.6565C12.8435 34.3574 12.1777 35.0329 12.1777 35.858C12.1777 36.6834 12.8435 37.3581 13.6565 37.3581Z"
        fill="white"
      />
      <path
        d="M13.6565 29.8561H28.6344C29.4475 29.8561 30.1132 29.181 30.1132 28.3556C30.1132 27.5306 29.4475 26.8555 28.6344 26.8555H13.6565C12.8435 26.8555 12.1777 27.5306 12.1777 28.3556C12.1777 29.181 12.8435 29.8561 13.6565 29.8561Z"
        fill="white"
      />
      <path
        d="M28.0575 42.0078H13.6565C12.8435 42.0078 12.1777 42.6833 12.1777 43.5079C12.1777 44.3334 12.8435 45.0085 13.6565 45.0085H28.0575C28.871 45.0085 29.5363 44.3334 29.5363 43.5079C29.5363 42.6833 28.871 42.0078 28.0575 42.0078Z"
        fill="white"
      />
      <path
        d="M47.7643 35.183C54.9978 36.1962 60.5625 42.395 60.5625 49.8983C60.5625 58.101 53.9011 64.7543 45.6877 64.7543C40.0411 64.7543 35.1177 61.6084 32.6091 56.971C31.4658 54.8621 30.8125 52.4504 30.8125 49.8869C30.8125 41.6843 37.4743 35.0312 45.6877 35.0312C46.3878 35.043 47.0759 35.0898 47.7643 35.183Z"
        fill="#FFC453"
      />
      <path
        d="M50.8872 49.5112H46.6583V43.3751C46.6583 42.5497 45.9934 41.875 45.18 41.875C44.3669 41.875 43.7012 42.5497 43.7012 43.3751V51.0117C43.7012 51.8371 44.352 52.5118 45.1654 52.5118H50.9021C51.7152 52.5118 52.3809 51.8371 52.3809 51.0117C52.3809 50.1867 51.7152 49.5112 50.8872 49.5112Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_8301_19942">
        <rect width="68" height="69" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
